import React from 'react';
import Widget from '../verticalWidget';

const CategoryWidget3 = (props) => {
  return (
    <div>
      <Widget cardType={3} {...props} />
    </div>
  );
};

export default CategoryWidget3;
